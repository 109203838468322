.form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

button, input {
    overflow: visible;
}
input, button, select, optgroup, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

.btn:hover {
    color: white;
    background-color: #3d713f;
    border-color: black;
}
.container, .container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.p-30 {
    padding: 30px;
}
.bg-light {
    background-color: #FFFFFF !important;
}

.text-uppercase {
    text-transform: uppercase !important;
}
.mb-4, .my-4 {
    margin-bottom: 1.5rem !important;
}
.position-relative {
    position: relative !important;
}
.h1, h2, h3 {
    font-weight: 700 !important;
}
@media (max-width: 1200px) {
    h2, .h2 {
        font-size: calc(1.325rem + 0.9vw);
    }
}
/* h2, .h2 {
    font-size: 2rem;
}
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
    color: #3D464D;
}
h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
}
 */
